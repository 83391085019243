<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head ng-block-head-lg d-print-none">
			<div class="ng-block-head-sub d-print-none">
				<router-link class="back-to" to="/invoices">
					<em class="icon ni ni-arrow-left-c mr-1"></em>
					<span>Invoices</span>
				</router-link>
			</div>
			<div class="ng-block-between-md g-4">
				<div class="ng-block-head-content">
					<h2 class="ng-block-title fw-normal">Invoice #746F5K2</h2>
					<div class="ng-block-des d-print-none">
						<p>Your invoice details are given bellow.</p>
					</div>
				</div>
			</div>
		</div>
		<div class="ng-block border-0">
			<div class="invoice border-0">
				<div class="invoice-wrap border-0">
					<div class="invoice-head">
						<div class="invoice-contact">
							<span class="overline-title">Invoice To</span>
							<div class="invoice-contact-info">
								<h4 class="title">Gregory Anderson</h4>
								<ul class="list-plain">
									<li>
										<em
											class="icon ni ni-map-pin-fill"
										></em>
										<span
											>House #65, 4328 Marion Street<br />Newbury,
											VT 05051</span
										>
									</li>
									<li>
										<em class="icon ni ni-call-fill"></em>
										<span>+012 8764 556</span>
									</li>
								</ul>
							</div>
						</div>

						<div class="invoice-desc">
							<h3 class="title">Invoice</h3>
							<ul class="list-plain">
								<li class="invoice-id">
									<span>Invoice ID</span>:
									<span>66K5W3</span>
								</li>
								<li class="invoice-date">
									<span>Date</span>:
									<span>26 Jan, 2020</span>
								</li>
							</ul>
						</div>
					</div>

					<div class="invoice-bills">
						<div class="table-responsive">
							<table class="table table-striped">
								<thead>
									<tr>
										<th>Item ID</th>
										<th>Description</th>
										<th>Price</th>
										<th>Qty</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>24108054</td>
										<td>
											Dashlite - Conceptual App Dashboard
											- Regular License
										</td>
										<td>$40.00</td>
										<td>5</td>
										<td>$200.00</td>
									</tr>
									<tr>
										<td>24108054</td>
										<td>6 months premium support</td>
										<td>$25.00</td>
										<td>1</td>
										<td>$25.00</td>
									</tr>
									<tr>
										<td>23604094</td>
										<td>
											Invest Management Dashboard -
											Regular License
										</td>
										<td>$131.25</td>
										<td>1</td>
										<td>$131.25</td>
									</tr>
									<tr>
										<td>23604094</td>
										<td>6 months premium support</td>
										<td>$78.75</td>
										<td>1</td>
										<td>$78.75</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td colspan="2"></td>
										<td colspan="2">Subtotal</td>
										<td>$435.00</td>
									</tr>
									<tr>
										<td colspan="2"></td>
										<td colspan="2">Processing fee</td>
										<td>$10.00</td>
									</tr>
									<tr>
										<td colspan="2"></td>
										<td colspan="2">TAX</td>
										<td>$43.50</td>
									</tr>
									<tr>
										<td colspan="2"></td>
										<td colspan="2">Grand Total</td>
										<td>$478.50</td>
									</tr>
								</tfoot>
							</table>
							<div class="ng-notes ff-italic fs-12px text-soft">
								Invoice was created on a computer and is valid
								without the signature and seal.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script lang="ts">
	import { defineComponent, ref, onMounted, toRefs } from "vue";
	import http from "../services/http";
	import store from "../services/store";

	export default defineComponent({
		props: {
			id: String
		},
		setup(props) {
			const invoice = ref({});
			const { user } = toRefs(store.state);

			function fetchInvoice() {
				http.get(`/api/invoices/${props.id}`).then(
					(res: any) => {
						invoice.value = res.data;
					}
				);
			}

			onMounted(() => {
				fetchInvoice();
			});

			return { invoice, user };
		},
	});
</script>
