<template>
	<InvoiceTemplate :invoice="invoice" :viewer="user.id" />
</template>

<script>
	import store from "../../../../../common/services/store";
	import http from "../../../../../common/services/http";
	import router from "../../router";
	import { defineComponent, reactive, ref, onMounted, toRefs } from "vue";
	import InvoiceTemplate from "../../../../../common/components/Invoice.vue";

	export default defineComponent({
		components: {
			InvoiceTemplate,
		},
		setup() {
			const invoice = ref({
				user: {},
				order: {},
				payment: {},
			});

			const { user } = toRefs(store.state);

			onMounted(() => {
				http.get(
					`/api/invoices/${router.currentRoute.value.params.id}`
				).then((response) => {
					invoice.value = response.data;
				});
			});

			return {
				user,
                invoice
			};
		},
	});
</script>
